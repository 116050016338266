import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { pick } from "lodash";
import DeviseTokenService, { authHeadersValid, saveAuthHeaders } from "@/core/services/DeviseTokenService";
import { baseURL } from "@/core/data/constants";
import qs from "qs";
import Swal from 'sweetalert2'
import _escape from 'lodash/escape'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = baseURL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setDefaults(): void {
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] = "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] = "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Content-Encoding"] = "UTF-8";
    ApiService.vueInstance.axios.defaults.paramsSerializer = (params) => {
      return qs.stringify(params, {arrayFormat: 'brackets'});
    };
  }

  private static pullAuthHeadersFromResponse(headers) {
    const authHeaders = pick(headers, ["access-token", "client", "expiry", "uid"]);
    if (authHeadersValid(authHeaders)) {saveAuthHeaders(authHeaders);}
  }

  public static setInterceptors(): void {
    ApiService.vueInstance.axios.interceptors.request.use((config) => {
      const authHeaders = DeviseTokenService.getAuthHeaders();
      if (authHeaders) {
        config.headers["access-token"] = authHeaders["access-token"];
        config.headers["client"] = authHeaders["client"];
        config.headers["uid"] = authHeaders["uid"];
      }

      return config;
    });

    ApiService.vueInstance.axios.interceptors.response.use((response) => {
      this.pullAuthHeadersFromResponse(response.headers);
      return response;
    }, async (error) => {
      const response = error.response;
      if (response) {
        this.pullAuthHeadersFromResponse(response.headers);
      }

      if (!error.config.errorHandled && response.status != 401 && process.env.NODE_ENV !== "production") {
        let html = `Ошибка при выполнении запроса!<br />Статус: ${response.status}<br /><br />`;

        html += `<div>Error: <b>${response.data.error}</b></div>`;
        html += `<hr /><div class="margin-top: 5px">Exception: <b>${_escape(response.data.exception)}</b></div>`;

        if (response.data.traces) {
          if (response.data.traces["Application Trace"]) {
            const applicationTraces = response.data.traces["Application Trace"].map(o => o.trace).join("<br />");
            html += `<hr /><div class="margin-top: 5px">${applicationTraces}</div>`;
          }

          if (response.data.traces["Full Trace"]) {
            const fullTraces = response.data.traces["Full Trace"].map(o => o.trace).join("<br />");
            html += `<hr /><div class="margin-top: 5px">${fullTraces}</div>`;
          }
        }
        await Swal.fire({
          html: html,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
            popup: "swal-x-wide",
          },
        });
      }


      throw error;
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param path: string
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static async get(
    path: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.get(path, config);
  }

  /**
   * @description set the POST HTTP request
   * @returns Promise<AxiosResponse>
   * @param path
   * @param data
   * @param config
   */
  public static async post(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    config: AxiosRequestConfig | undefined = undefined,
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.post(path, data, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param path
   * @param data
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static async update(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    config: AxiosRequestConfig | undefined = undefined,
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.patch(path, data, config);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static async put(
    resource: string,
    params: AxiosRequestConfig,
  ): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static async delete(resource: string): Promise<AxiosResponse> {
    return await ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
