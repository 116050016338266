export const apiHost: string = process.env.NODE_ENV === "production" ? "https://api.traveluslugi.ru" : "http://localhost:3000";
export const wsHost: string = process.env.NODE_ENV === "production" ? "wss://api.traveluslugi.ru" : "ws://localhost:3000";
export const baseURL = `${apiHost}/admins`;
export const firebaseConfig = {
  apiKey: "AIzaSyCveIwpQqmWpJLDDSZhmvv7U3jWMcaVPms",
  authDomain: "traveluslugi.firebaseapp.com",
  projectId: "traveluslugi",
  storageBucket: "traveluslugi.appspot.com",
  messagingSenderId: "154053698838",
  appId: "1:154053698838:web:23e690997ba0535484dc37",
};
export const firebaseVapidKey = "BBHrNlHRG8BujlXEViJv3tj8WGFG_RrkhSfuS5h8cJZ4AQwDEQz-kiWpxFYg2kWkl3ZKlMsRcNtwJCOBiV1YxL0";