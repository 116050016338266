import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/users",
    name: "root",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/users",
        name: "users-index",
        component: () => import("@/views/Users/UsersIndex.vue"),
      },
      {
        path: "/users/:user_id",
        name: "user-profile",
        redirect: {name: 'user-profile-settings'},
        component: () => import("@/views/Users/UsersEdit.vue"),
        children: [
          {
            path: "settings",
            name: "user-profile-settings",
            component: () => import("@/views/Users/Profile/GeneralSection.vue"),
          },
          {
            path: "documents",
            name: "user-profile-documents",
            component: () => import("@/views/Users/Profile/ProfileDocumentsSection.vue"),
          },
          {
            path: "documents-finances",
            name: "user-profile-finances",
            component: () => import("@/views/Users/Profile/ProfileFinancesSection.vue"),
          },
          {
            path: "documents",
            name: "user-profile-approve",
            component: () => import("@/views/Users/Profile/ProfileApproveSection.vue"),
          },
          {
            path: "dwellings",
            name: "user-dwellings",
            component: () => import("@/views/Users/Dwellings/UserDwellingsIndex.vue"),
          },
          {
            path: "changes",
            name: "user-profile-changes-history",
            component: () => import("@/views/Users/Profile/ProfileChangesHistory.vue"),
          },
        ]
      },
      {
        path: "support-tickets",
        name: "support-index",
        component: () => import("@/views/Support/SupportIndex.vue"),
      },
      {
        path: "support-tickets/new",
        name: "support-new",
        component: () => import("@/views/Support/SupportNew.vue"),
      },
      {
        path: "support-tickets/:support_ticket_id",
        name: "support-ticket-page",
        component: () => import("@/views/Support/SupportTicketPage.vue"),
      },
      {
        path: "/users/:user_id/dwellings/:dwelling_id",
        name: "dwelling-show",
        redirect: {name: 'dwelling-documents'},
        component: () => import("@/views/Dwellings/DwellingShow.vue"),
        children: [
          {
            path: 'documents',
            name: 'dwelling-documents',
            component: () => import("@/views/Dwellings/DwellingDocuments.vue")
          },
          {
            path: 'approve',
            name: 'dwelling-approve',
            component: () => import("@/views/Dwellings/DwellingApproveForm.vue")
          },
          {
            path: 'general',
            name: 'dwelling-general',
            component: () => import("@/views/Dwellings/DwellingGeneralValues.vue")
          },
          {
            path: 'address',
            name: 'dwelling-address',
            component: () => import("@/views/Dwellings/DwellingAddress.vue")
          },
          {
            path: 'complaints',
            name: 'dwelling-complaints',
            component: () => import("@/views/Dwellings/DwellingComplaints.vue")
          },
          {
            path: 'photos',
            name: 'dwelling-photos',
            component: () => import("@/views/Dwellings/DwellingPhotos.vue")
          },
          {
            path: 'changes-history',
            name: 'dwelling-history',
            component: () => import("@/views/Dwellings/DwellingHistory.vue")
          }
        ]
      },
      {
        path: "/reviews",
        name: "reviews-base",
        redirect: {name: "reviews-from-guests"},
        component: () => import("@/views/Reviews/ReviewsBase.vue"),
        children: [
          {
            path: "/reviews/from-guests",
            name: "reviews-from-guests",
            component: () => import("@/views/Reviews/ReviewsFromGuests.vue"),
          },
          {
            path: "/reviews/from-hosts",
            name: "reviews-from-hosts",
            component: () => import("@/views/Reviews/ReviewsFromHosts.vue"),
          }
        ]
      },
      {
        path: "/changes-log",
        name: "changes-log",
        component: () => import("@/views/ChangesLog/ChangesLogIndex.vue"),
      },
      {
        path: "/forbidden-words",
        name: "forbidden-words-index",
        component: () => import("@/views/ForbiddenWords/ForbiddenWordsIndex.vue"),
      },
      {
        path: "/coupons",
        name: "coupons-index",
        component: () => import("@/views/Coupons/CouponsIndex.vue"),
      },

    ],
  },
  {
    path: "/coming-soon",
    component: () => import("@/views/ComingSoon.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {allowUnauthorized: true},
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (routeFrom, routeTo) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  const authorized = await store.dispatch(Actions.VERIFY_AUTH);
  if (!authorized) {
    console.log({routeFrom, routeTo});
    if (!routeFrom.meta.allowUnauthorized) {
      await router.push({name: "sign-in", query: {return_url: encodeURIComponent(routeFrom.fullPath)}});
      return false;
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
