import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosError } from "axios";
import DeviseTokenService from "@/core/services/DeviseTokenService";

export interface DeviseTokenAuthAuthHeaders {
  "access-token"?: string;
  client?: string;
  expiry?: string;
  uid?: string;
}

export interface User {
  allow_password_change: boolean;
  email: string;
  id: number;
  image: string | null;
  name: string;
  nickname: string | null;
  profile: unknown;
  provider: string;
  uid: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = DeviseTokenService.authHeadersValid(null);

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](errors) {
    this.errors = errors;
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_USER_FIELDS](payload) {
    Object.assign(this.user, payload);
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    DeviseTokenService.voidAuthHeaders();
  }

  @Action({ rawError: true })
  async [Actions.LOGIN](credentials) {
    await ApiService.post("/auth/sign_in", credentials)
                    .then(() => {
                      this.context.commit(Mutations.SET_AUTH);
                    })
                    .catch(({ response }) => {
                      if (response?.data?.errors) {
                        this.context.commit(Mutations.SET_ERROR, response.data.errors);
                      } else {
                        this.context.commit(Mutations.SET_ERROR, ["Неизвестная ошибка"]);
                      }
                    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  async [Actions.REGISTER](credentials) {
    return await ApiService.post("/auth", credentials)
                           .then(() => {
                             this.context.commit(Mutations.SET_AUTH);
                           })
                           .catch(({ response }) => {
                             if (response?.data?.errors) {
                               this.context.commit(Mutations.SET_ERROR, response.data.errors);
                             } else {
                               this.context.commit(Mutations.SET_ERROR, ["Неизвестная ошибка"]);
                             }
                           });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
                     .then(() => {
                       this.context.commit(Mutations.SET_ERROR, {});
                     })
                     .catch(({ response }) => {
                       if (response?.data?.errors) {
                         this.context.commit(Mutations.SET_ERROR, response.data.errors);
                       } else {
                         this.context.commit(Mutations.SET_ERROR, ["Неизвестная ошибка"]);
                       }
                     });
  }

  @Action
  async [Actions.VERIFY_AUTH]() {
    if (DeviseTokenService.getAuthHeaders()) {
      ApiService.setDefaults();

      try {
        const response = await ApiService.get("/auth/validate_token");
        if (response.status === 200) {
          this.context.commit(Mutations.SET_AUTH);
          this.context.commit(Mutations.SET_USER, response.data.data);
        }
        return true;
      } catch (e: unknown) {
        const response = (e as AxiosError).response;

        if (response) {
          this.context.commit(Mutations.PURGE_AUTH);
          if (response?.data?.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          } else {
            this.context.commit(Mutations.SET_ERROR, ["Неизвестная ошибка"]);
          }
        }

        return false;
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);

      return false;
    }
  }
}
